const Star = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_4790_1233)'>
        <path
          d='M10.892 3.66391C11.3019 2.67836 12.6981 2.67836 13.108 3.66391L14.8926 7.95456C15.0654 8.37004 15.4561 8.65392 15.9047 8.68988L20.5368 9.06123C21.6007 9.14653 22.0322 10.4743 21.2215 11.1687L17.6924 14.1919C17.3506 14.4846 17.2014 14.9439 17.3058 15.3816L18.384 19.9018C18.6317 20.9401 17.5022 21.7607 16.5912 21.2043L12.6255 18.7821C12.2415 18.5475 11.7585 18.5475 11.3745 18.7821L7.40876 21.2043C6.49784 21.7607 5.36834 20.9401 5.61601 19.9018L6.69423 15.3816C6.79864 14.9439 6.64939 14.4846 6.30765 14.1919L2.77847 11.1687C1.96782 10.4743 2.39926 9.14653 3.46324 9.06123L8.09535 8.68988C8.5439 8.65392 8.93463 8.37004 9.10744 7.95456L10.892 3.66391Z'
          stroke='currentColor'
          strokeWidth='1.5'
        />
      </g>
      <defs>
        <clipPath id='clip0_4790_1233'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Star;
